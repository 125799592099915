<template>
  <va-card title="Subscribe Request">
    <div class="mb-3">
      <h2 class="display-4 mb-1">Email</h2>
      <span>{{ email }}</span>
    </div>

    <div class="mb-3" v-if="created_at">
      <h2 class="display-4 mb-1">Created at</h2>
      <span>{{ created_at }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      email: '',
      created_at: '',
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
    adminUrl () {
      return process.env.VUE_APP_AGRO_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/subscribe-request/${this.$attrs.id}?lang=${this.locale}`)
        .then(response => {
          this.email = response.data.email
          const date = new Date(response.data.created_at * 1000)
          this.created_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
